import React from "react"
import "../../components/my_global.scss"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Container, Row, Col } from "reactstrap"

import { StaticImage } from "gatsby-plugin-image"
import "../../components/prizes.css"

// images

const Solardesalination = () => {
  return (
    <Layout>
      <SEO title={"What We're Reading"} />
      <div className="page-heading reading-bg">
        <Container>
          <Row>
            <Col lg="8" className="mx-auto text-center text-white">
              <h3 className="h3s"> American-Made Challenges</h3>
              <h2 className="h2s">Solar Desalination Prize Round 2</h2>
              <p
                className="page-hero-subheading"
                style={{ textAlign: "justify" }}
              >
                The American-Made Challenges: Solar Desalination Prize is a
                multi-stage prize competition designed to accelerate the
                development of low-cost desalination systems that use
                solar-thermal power to produce clean drinking water from
                saltwater. Each stage of the competition will have increasing
                prize amounts, totaling millions of dollars.
              </p>
              <div className="column is-12">
                <div className="has-text-centered">
                  <a href="https://www.herox.com/solardesalination">
                    <button type="button" className="btns">
                      Join the Challenge?
                    </button>
                  </a>
                </div>
                <span className="whitespace"></span>
              </div>
            </Col>
          </Row>
          <StaticImage src="../../images/stick.webp" className="stick" alt="stick" loading="eager"/>
        </Container>
      </div>
      <div className="section_padding">
        <div className="container">
          <p className="pcol-fz">
            The Solar Desalination Prize is a four-phase prize competition that
            will advance thermal desalination technologies to expand the
            utilization of non-traditional water sources, ensure water security,
            and improve the resilience of U.S. infrastructure.
          </p>
          <h1 className="mac_h1">The Prize Structure</h1>
          <div>
            <div className="row rww">
              <div className="col pcol-fz">
                <p>
                  The Solar Desalination Prize has a rapid iteration prize
                  structure designed to help entrepreneurs use innovative
                  research to come up with ideas, then design and test concepts,
                  with the end goal of having shovel-ready technology primed for
                  industry adoption.
                </p>
                <p>
                  Millions of dollars in prizes will be awarded over the
                  competition, increasing in value in each phase, culminating in
                  a $1 million grand prize at the end of the competition for
                  successful testing and demonstration of promising solar
                  desalination prototypes.
                </p>
              </div>
              <div className="col">
                <StaticImage
                  src="../../images/solardes.webp"
                  className="img-styl"
                  alt="solardes"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
        <span style={{ padding: "5px" }}></span>
        <div className="container">
          <h2 className="mac_h1">Who can Participate</h2>
          <hr />
          <p className="pcol-fz">
            Entrepreneurs, technologists, hardware developers, engineers, solar
            experts, and investors are all encouraged to join the challenge, be
            part of the American-Made Network and create ground-breaking
            solutions that will accelerate solar desalination technologies.
          </p>
        </div>
        <span style={{ padding: "5px" }}></span>
        <div className="container">
          <h1 className="mac_h1">Contests & Important Dates</h1>
          <hr />
          <div className="col">
            <StaticImage
              className="img-styl"
              src="../../images/solardes2.webp"
              alt="solardes2"
              loading="lazy"
            />
            <hr />
            <div>
              <h3 className="mac_h1"> Innovation Contest</h3>
              <ul className="boxy-bullets gold-bullets ps list-styled">
                <li>Open: April 2021</li>
                <li>Open: April 2021</li>
                <li>Quarterfinalists announced Sept. 2021</li>
                <li>
                  Review the technical specifications that teams should consider
                  during the Innovation Contest
                </li>
              </ul>
              <hr />
              <h3 className="mac_h1"> Teaming Contest</h3>
              <ul className="boxy-bullets gold-bullets ps list-styled">
                <li>Open: Sept. 2021</li>
                <li>Close: Feb. 2022</li>
                <li>Semifinalists will be announced April 2022</li>
              </ul>
              <hr />
              <h3 className="mac_h1"> Design Contest</h3>
              <ul className="boxy-bullets gold-bullets ps list-styled">
                <li>Open: April 2022</li>
                <li>Submissions accepted: As soon as competitors are ready</li>
                <li>
                  Finalists are expected to be announced one month after DOE
                  receives submissions
                </li>
                <li>Close: April 2025</li>
              </ul>
              <hr />
              <h3 className="mac_h1"> Design Contest</h3>
              <ul className="boxy-bullets gold-bullets ps list-styled">
                <li>Open: April 2022</li>
                <li>
                  Submissions accepted: The second Monday in April annually
                </li>
                <li>
                  Winners are expected to be announced one month after the DOE
                  site visit
                </li>
              </ul>
              <hr />
              <h4>
                {" "}
                <a href="https://www.herox.com/SolarDesalination">
                  {" "}
                  Follow the prize{" "}
                </a>{" "}
                to be updated on future dates and the official rules.
              </h4>
              <b class="is-size-7">
                All dates are subject to change including contest openings,
                deadlines, and announcements.
              </b>
            </div>
          </div>
        </div>
        <span style={{ padding: "5px" }}></span>
        <div className="container">
          <h3 className="h3ps"> Solutions that help shape the future </h3>
          <div className="col">
            <StaticImage
              src="../../images/solar-desal2.jpg"
              className="img-styl"
              alt="solar desal 2"
              loading="lazy"
            />
          </div>
          <p className="hps">
            Are you a thinker, entrepreneur, facility, or potential partner?
            Anyone with an innovative idea can help promote transformation by
            participating in the American-Made Challenges.
          </p>
          <div style={{ textAlign: "center" }}>
            <a href="#">
              <button type="button" class="btns">
                Ready for a Challenge?
              </button>
            </a>
          </div>
        </div>
        <span style={{ padding: "5px" }}></span>
      </div>
    </Layout>
  )
}

export default Solardesalination
